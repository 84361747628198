import i18next from "i18next";

i18next.init({
  react: {
   // wait: true,
    useSuspense: false,
  },
  fallbackLng: "en",
  resources: {
    /*  "pt": {
            translations: require('../locales/pt/translations.json')
        },*/
    en: {
      translations: require("../locales/en/translations.min.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false,
  },
});

i18next.languages = ["pt", "en"];

export default i18next;
